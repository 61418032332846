import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import RoleListItem from "../components/RoleListItem";
import SourcingFiltersModal from "../components/SourcingFiltersModal";
import PaginationControl from "../components/PaginationControl";
import { getRoles, createRole, saveRole, getSourcingCities, getCompaniesToSource } from "../services";
import { useAuth } from '../hooks';
import Spinner from "../components/Spinner";
import BlueButton from "../components/BlueButton";
import PageContainer from "../components/PageContainer";
import { AiFillLinkedin } from 'react-icons/ai';
import { Rating } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css';
import { Tooltip } from 'react-tooltip';


const COMPANY_FUNDING = ['early_stage', 'late_stage', 'public', 'big_tech'];

const Container = styled.div`
    margin-top: 7rem;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    box-sizing: border-box;
    align-self: flex-start;
    max-width: 80rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    .content {
      flex: 1;
      position: relative;
      .companies-count {
        padding: 1rem;
        margin-top: 0.5rem;
        font-family: Roboto;
        font-weight: 400;
      }
      .company-item {
        padding: 1rem;
        min-height: 3rem;
        margin-top: 0.5rem;
        font-family: Roboto;
        font-weight: 400;
        min-width: 56rem;
        > a {
          color: black;
          text-decoration: none;
          font-size: 1.2rem;
        }
        cursor: pointer;
        width: fit-content;
        > div.description {
          display: flex;
          align-items: flex-start;
          font-size: 0.9rem;
          margin-top: 0.5rem;
          label {
            font-weight: 400;
          }
          .location {
            width: 15rem;
          }
          .lfr {
            width: 30rem;
          }
          .linkedin {
            color: black;
          }
          .rating-container {
            max-width: 6rem;
            display: inline-block;
            margin: 0 1rem;
          }
          .rating-placeholder {
            width: 6rem;
            display: inline-block;
            margin: 0 1rem;
          }
        }  
        &:hover {
          background-color: var(--color-grey-00);
          > a {
            text-decoration: underline;
          }
        }
      }
    }
    .no-roles {
      font-size: 0.9rem;
      margin-top: -0.2rem;
      max-width: 39rem;
      font-family: Roboto;
      font-weight: 400;
      text-align: left;
    }
    .filter-container {
      width: 14rem;
      margin-bottom: 2rem;
    }
    .spacer {
      flex: 1;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      width: 55rem;
    }
    .grey-line {
      width: 56rem;
      height: 0;
      border-bottom: 1px solid var(--light-greye4);
      margin: 2rem 0 1rem;
    }
    .spinner {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      left: 15rem;
      justify-content: center;
      display: flex;
      align-items: center;
    }
`

const Companies = () => {
  const [ cities, setCities ] = useState([]);
  const [ filters, setFilters ] = useState(null);
  const [ roles, setRoles ] = useState([]);
  const [ page, setPage ] = useState(1);
  const [ companiesCount, setCompaniesCount] = useState(null);
  const [ isRoleModalOpen, setRoleModalOpen ] = useState(false);
  const rolesPerPage = 10;
  const [ loading, setLoading ] = useState(true);
  const isLoading = useRef(false);
  // useAuth();

  const onPageSelect = (n) => {
    window.scrollTo(0,0);
    setPage(n);
  }

  useEffect(() => {
    if (!isLoading.current) {
      fetchData();
    }
  }, [])

  const fetchData = async () => {
    setLoading(true);
    isLoading.current = true;
    try {
      // const data = await getSourcingCities();
      // if (data) {
      //   setCities(data.map(item => ({label: item.city, value: item.id})));
      // }
      const res = await getCompaniesToSource({
        company_funding_stages: [],
        location: [],
        stack_skills: []
      });
      if (res?.companies) {
        setRoles(res?.companies);
      }
      if (res?.company_count) {
        setCompaniesCount(res?.company_count)
      }
    } catch(err) {
      setCities([])
      // TODO this should notify the user that a problem occurred
    } finally {
      setLoading(false);
      isLoading.current = false;
    }
  }


  const onRoleModalClose = () => {
    setRoleModalOpen(false);
  }

  const openRoleModal = (item) => {
    setRoleModalOpen(true);
  }

  const applyFilters = async (data) => {
      console.log(data);
      setRoleModalOpen(false);
      setFilters(data);
      setLoading(true);
      isLoading.current = true;
      try {
        const company_funding_stages = data?.company_funding_stages ? [data?.company_funding_stages] : [];
        const res = await getCompaniesToSource({
          company_funding_stages,
          location: data.location,
          stack_skills: data.stack_skills,
          title_keywords: data.title_keywords,
          rating: data.rating,
          three_year_funding_anniversary: data.three_year_funding_anniversary,
          four_year_funding_anniversary: data.four_year_funding_anniversary
        });
        if (res?.companies) {
          setRoles(res?.companies);
        }
        if (res?.company_count) {
          setCompaniesCount(res?.company_count)
        }
      } catch (err) {
      } finally {
        setLoading(false);
        isLoading.current = false;
      }
  }

  const renderContent = () => {
    if (loading) return (
      <div className="content">
        <div className="spinner">
          <Spinner/>
        </div>
      </div>
    );
    return  <div className="content">
      <BlueButton onClick={ openRoleModal } style={{marginBottom: '1rem'}}>Filters</BlueButton>
      <div className="grey-line"></div>

      {
        companiesCount &&
        <div className="companies-count">{companiesCount} companies found.</div>
      }

      {
        roles.slice((page-1)*rolesPerPage, page*rolesPerPage)
        .map((item, i) => (
          <div key={i+item.name} className="company-item"> 
              <a href={item.url} target="__blank">{item.name}</a>
              <div className="description">
                <div className="location">{item.city}</div>
                <div className="lfr">{item.latest_funding_round}</div>
                {
                  item.rating ?
                  <div 
                    className="rating-container"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={`Rating ${item.rating}`}
                    data-tooltip-place="top"
                  >
                      <Rating readOnly value={item.rating} />
                  </div> :
                  <div className="rating-placeholder"/>  
                }
                {
                  item.linkedin_url &&
                  <a 
                    target='_blank' 
                    rel='noreferrer' 
                    href={item.linkedin_url}>
                        <AiFillLinkedin size={'1.2rem'} color={'black'}/>
                  </a>
                }
              </div>
          </div>
        ))
      }
      <div className="spacer"/>
      {
        !loading.current &&
        <div className="controls">
          <div/>
          {
            roles.length < rolesPerPage ?
            <div/> :
            <PaginationControl 
              selected={page} 
              total={Math.ceil(roles.length/rolesPerPage)} 
              onClick={onPageSelect}/>
          }
        </div>
      }
    </div>
  }

  return (
    <>
    <PageContainer title={'Companies'} selected={'companies'}>
      <Container>
      { renderContent() }
      </Container>
    </PageContainer>
    <SourcingFiltersModal 
      isOpen={ isRoleModalOpen }
      cities = { cities }
      data={ filters }
      onSubmit={ applyFilters }
      onClose={ onRoleModalClose }
    />
    <Tooltip id="my-tooltip"/>
    </>
  );
};

export default Companies;