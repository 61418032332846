import React, {useEffect} from "react";
import "./App.css";
import { 
  Main, 
  Sourcing,
  ResumeAssessment, 
  QAHelper, 
  ATSIntegration, 
  Billing, 
  GetStarted, 
  Pricing,
  Login,
  Signup,
  ResumeBuilder,
  HonestResume,
  Search,
  AllRoles,
  Companies,
  SourcingRoles
 } from './pages';
import LoginModal from "./components/LoginModal";
import LoadingScreen from "./components/LoadingScreen";
import ContactUsModal from "./components/ContactUsModal";
import { firebaseAuth } from "./services/firebase";
import { 
  getSkills, 
  getAllRoles,
  titlesToSource,
  getUSStates,
  getUSCities,
  apiReplayer  
} from "./services";
import { useAtom } from 'jotai';
import { 
  userAtom, 
  skillAtom, 
  rolesAtom, 
  titlesAtom, 
  citiesAtom,
  statesAtom
} from "./atoms";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";

var mobile = require('is-mobile');

const ROUTES = [
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/resume-assessment",
    element: <ResumeAssessment />
  },
  {
    path: "/honest-resume",
    element: <HonestResume />
  },
  {
    path: "tools/qa-helper",
    element: <QAHelper />
  },
  {
    path: "/tools/resume-builder",
    element: <ResumeBuilder />
  },
  {
    path: "ats",
    element: <ATSIntegration />
  },
  {
    path: "billing",
    element: <Billing />
  },
  {
    path: "get-started",
    element: <GetStarted />
  },
  {
    path: "pricing",
    element: <Pricing />
  },
  {
    path: "login",
    element: <Login />
  },
  {
    path: "signup",
    element: <Signup />
  },
  {
    path: "search",
    element: <Search />
  },
  {
    path: "roles",
    element: <AllRoles />
  },
  {
    path: "sourcing",
    element: <Sourcing />
  },
  {
    path: "sourcing-roles",
    element: <SourcingRoles />
  },
  // {
  //   path: "companies",
  //   element: <Companies />
  // },
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
]

const MOBILE_ROUTES = [
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
]

const router = createBrowserRouter(mobile() ? MOBILE_ROUTES : ROUTES);

const App = () => {
  const [user, setUser] = useAtom(userAtom);
  const [skills, setSkills] = useAtom(skillAtom);
  const [roles, setRoles] = useAtom(rolesAtom);
  const [titles, setTitles] = useAtom(titlesAtom);
  const [states, setStates] = useAtom(statesAtom);
  const [cities, setCities] = useAtom(citiesAtom);

  useEffect(() => {
      let unsubscribe;
      const checkAuth = async () => {
        unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
            setUser(user);
        });
      } 
      checkAuth();
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      }
  }, [setUser]);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const res = await getSkills();
        if (res?.skill_names) {
          setSkills(res?.skill_names);
        }
      } catch (err) {
        setTimeout(() => {
          fetchSkills();
        }, 500)
      }
    }
    if (
        user && 
        window.location.pathname !== '/honest-resume'
      ) {
      if (!skills) {
        fetchSkills();
      }  
    }
  }, [user, skills, setSkills])

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const res = await getAllRoles();
        if (res && res.length > 0) {
          setRoles(res);
        }
      } catch (err) {
        setTimeout(() => {
          fetchRoles();
        }, 500)
      }
    }
    if (
        user && 
        window.location.pathname !== '/honest-resume'
      ) {
      if (!roles) {
        fetchRoles();
      }
    }
  }, [user, roles, setRoles])

  useEffect(() => {
    const fetchTitles = async () => {
      const res = await apiReplayer(titlesToSource, (res) => !!res?.titles_to_source?.length);
      if (res?.titles_to_source) {
        setTitles(res.titles_to_source.map(item => ({label: item[1], value: item[0]})))
      }
    }
    if (
        user && 
        window.location.pathname !== '/honest-resume'
      ) {
      if (!titles) {
        fetchTitles();
      }
    }
  }, [user, titles, setTitles])

  useEffect(() => {
    const fetchStates = async () => {
      const res = await apiReplayer(getUSStates, (res) => !!res?.us_states?.length);
      if (res?.us_states) {
        setStates(res.us_states.map(item => ({label: item[1], value: item[0]})))
      }
    }
    if (
        user && 
        window.location.pathname !== '/honest-resume'
      ) {
      if (!states) {
        fetchStates();
      }
    }
  }, [user, states, setStates])


  useEffect(() => {
    const fetchCities = async () => {
      const res = await apiReplayer(getUSCities, (res) => !!res?.us_cities?.length);
      if (res?.us_cities) {
        setCities(res.us_cities.map(item => ({label: item[1], value: item[0]})))
      }
    }
    if (
        user && 
        window.location.pathname !== '/honest-resume'
      ) {
      if (!cities) {
        fetchCities();
      }
    }
  }, [user, cities, setCities])

  return (
    <>
      <RouterProvider router={router} />
      <LoginModal/>
      <ContactUsModal/>
      <LoadingScreen/>
    </>
  );
};

export default App;