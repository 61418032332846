
import React, { useState } from "react";
import styled from 'styled-components';
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import WhiteButton from "./WhiteButton";
import Spinner from "./Spinner";
import PercentLoader from "./PercentLoader";
import { ReactComponent as PhoneIcon } from "../assets/images/phone.svg";
import { ReactComponent as CheckmarkYes } from "../assets/images/checkmark-yes.svg";
import { ReactComponent as CheckmarkNo } from "../assets/images/checkmark-no.svg";
import { ReactComponent as MailIcon } from "../assets/images/mail.svg";
import { ReactComponent as GoogleIcon } from "../assets/images/google.svg";
import { ReactComponent as LinkedinIcon } from "../assets/images/linkedin.svg";
import { FaMinus } from "react-icons/fa6";
import { Tooltip } from 'react-tooltip';
import HR from "../assets/images/hr.png";
import { BsArrowUpRightSquare } from "react-icons/bs";
import { getSourcedProfileDetails, markMessaged, whyProfile } from "../services";
import { BsBookmarkPlus } from "react-icons/bs";
import EditableName from "./EditableName";

const PERCENT_LOADER_VIEW = 4;

const Container = styled.div`
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 0.2rem;
    overflow: hidden;
    font-family: Roboto;
    font-weight: 400;
    .matches-role {
      padding: 0.1rem 0.5rem;
      min-width: auto;
      width: fit-content;
      height: auto;
      opacity: 0.7;
      font-weight: 500;
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 0.8rem;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.7;
      }
      &:focus {
        outline: none;
      }
    }

    .banner {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      font-size: 1rem;
      min-height: 3.5rem;
      position: relative;
      &:hover {
        .name { 
          .editable-name {
            svg.edit-icon {
              display: inherit !important;
            }
          }
        }
      }
      .filter_cnt {
        /* position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        font-size: 0.75rem;
        font-weight: 400;
        padding: 0.375rem !important;
        border-radius: 2px;
      }
      .label  {
        display: flex;
        gap: 1rem;
        align-items: center;
        span {
          font-size: 0.9rem;
          font-weight: 400;
          opacity: 0.8;
        }
        div {
          padding: 0;
          &.expand-arrow {
            cursor: pointer;
          }
        }

      }
      /* &:hover {
        .label {
          display: block !important;
        }
      } */
      
      .name {
        font-weight: 400;
        min-width: 10rem;
        .editable-name {
          font-size: inherit;
          svg.edit-icon {
            width: 0.8rem;
            margin-left: 0.4rem;
            display: none;
          }
        }
        a {
          text-decoration: none;
          color: black;
          &.hr-link {
            border: 1px solid black;
            border-radius: 2px;
            display: inline-block;
            margin-right: 0.5rem;
            padding: 1px 0 0 1px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
        .honest-resume {
          cursor: pointer;
          margin-left: 0.3rem;
          margin-bottom: -0.1rem;
        }
      }  
    }
    .loading-assessment {
      min-height: 7.25rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
    }
    .details {
      height: 0;
      overflow: hidden;
      width: 100%;
      /* background-color:  white; */
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      .filter-wrapper {
        flex: 1;
        margin: 1rem 0;
        font-size: 0.9rem;
        position: relative;
        h3 {
          margin: 0 0 0.7rem;
          font-size: 0.9rem;
        }
        .employments {
          margin: 0;
        }
        .education {
          margin: 1.5rem 0 2rem;
        }

        .employment-item {
          font-family: Roboto;
          font-weight: 400;
          margin-bottom: 0.3rem;
          &.extra-margin {
            margin-bottom: 0.7rem;
          }
          .employment-item-title-container {
            display: inline-flex;
            width: 25rem;
            max-width: 25rem;
            padding-right: 1rem;
            align-items: center;
            label.employment-item-title {
              overflow: hidden;
              max-width: 100%;
              display: inline-block;
              white-space: nowrap;
              text-overflow: ellipsis; 
              -o-text-overflow: ellipsis;
            }
          }
          .employment-item-company-container {
            display: inline-flex;
            width: 20rem;
            max-width: 20rem;
            align-items: center;
            label.employment-item-company {
              /* text-transform: uppercase; */
              padding-left: 0.3rem;
              overflow: hidden;
              max-width: 100%;
              display: inline-block;
              white-space: nowrap;
              text-overflow: ellipsis; 
              -o-text-overflow: ellipsis;
            }
          }
        }
        .why-john {
          position: absolute;
          top: 0rem;
          right: 0rem;
          font-size: 0.8rem;
          border-bottom: 1px dotted #000;
          cursor: pointer;
        }
        .mark-label {
          position: absolute;
          bottom: 0rem;
          right: 0rem;
          font-size: 0.8rem;
        }
        .message {
          position: absolute;
          bottom: 0rem;
          right: 0rem;
          font-size: 0.8rem;
          border: 2px solid #000;
        }
        .footer {
          margin-top: 2rem;
          .nice-to-haves {
            margin-top: 1rem;
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
        /* > button {
            padding: 0 1rem;
            font-size: 0.8rem;
            height: 2.1rem;
            min-width: 7.5rem;
            align-self: flex-end;
            position: absolute;
            right: 0;
            bottom: 0;
          } */
        .header {
          /* display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem; */
          .contacts {
            font-weight: 400;
            display: flex;
            flex-direction: row;
            gap: 1.9rem;  
            /* margin-bottom: 0.5rem;  */
            .contact {
              display: flex;
              gap: 0.5rem;
              align-items: center;
              &.last-modified {
                font-size: 0.85rem;
                align-self: baseline;
                font-weight: 300;
              }
              svg {
                height: 1rem; 
                width: 1rem;
              }
            }     
            a {
              color: black;
              text-decoration: none;
              font-weight: 300;
              font-size: 0.8rem;
              line-height: 1.25rem;
            }
          }
        }
  
        .attributes {
          font-weight: 500;
          display: flex;
          flex-direction: column;
          gap: 0.2rem;  
          margin-top: 1.3rem;
          .filter {
            font-family: Roboto;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            text-align: left;
          }
          /* margin-bottom: 0.5rem;         */
        }
        .inference {
          display: flex;
          flex-flow: wrap;
          gap: 0.4rem;
          /* margin-top: 2.2rem; */
          margin-top: 1rem;
        }
        .filters {
          flex: 1;
          display: flex;
          flex-flow: wrap;
          gap: 2rem;
          >div {
            /* flex: 0.5; */
            div.filter {
              font-weight: 400;
              margin-right: 1rem;
              margin-bottom: 0.1rem;
              display: flex;
              font-size: 0.75rem;
              align-items: center;
              gap: 1rem;
            }
          }
         
        }
      }
    }

    &.red {
      background-color: var(--color-red-0);
      .banner {
        .filter_cnt {
          border: 1px solid var(--color-red-1);
        }
      }
    }
    &.grey {
      background-color: var(--color-gray-2);
    }
    &.green {
      background-color: var(--color-green-0);
      .banner {
        .filter_cnt {
          border: 1px solid var(--color-green-1);
        }
      }
    }
    &.expanded {
      border: 1px solid hsl(0, 0%, 80%);
      /* .banner .label {
        display: block;
      } */
      .details {
        height: auto;
        background-color: white;
        border-top: 1px solid hsl(0, 0%, 80%);
      }
    }
    
`

export const Pill = styled.div`
  margin: 0.1rem 0 0 0.5rem;
  font-size: 0.7rem;
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  box-sizing: border-box;
  font-weight: 600;
  height: fit-content;
  width: fit-content;
  display: inline-block;
  &.green {
    background-color: var(--color-green1);
    color: white;
    border: 1px solid var(--color-green1);
  }
  &.red {
    background-color: var(--color-red-1);
    color: white;
    border: 1px solid var(--color-red-1);
  }
  &.grey {
    background-color: white;
    border: 1px solid #000000;
  }
  &.true-grey {
    background-color: var(--color-gray1);
    border: 1px solid var(--color-gray1);
    color: white;
  }
  &.orange {
    color: white;
    background-color: rgb(203,109,46);
  }
`

const SourcedProfileListItem = ({ data, profileStore, onWhyJohn, roleId }) => {
  const [ expanded, setExpanded ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ assessment, setAssessment ] = useState(null);
  const [ isMessaged, setIsMessaged ] = useState(false);

  const onAssessmentExpand = async () => {
    setExpanded(true);
    if (profileStore[data.profile_id]) {
      setAssessment(profileStore[data.profile_id]);
    } else {
      setIsLoading(true);
      try {
        const res = await getSourcedProfileDetails(data.profile_id, roleId);
        profileStore[data.profile_id] = res;
        setAssessment(res);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const onMessageClick = async () => {
    try {
     await markMessaged(data.profile_id);
     setIsMessaged(true);
    } catch (err) {

    } 
  }

  const onWhyJohnClick = async () => {
    try {
     if (data?.reason) {
      onWhyJohn(data.reason)
     } else {
      const res = await whyProfile(data.profile_id, roleId);
      if (res?.reason) {
       data.reason = res.reason; 
       onWhyJohn(res.reason)
      }
     }
    } catch (err) {

    } 
  }

  const renderLabel = () => {
    if (expanded) return (
      <div className="label">
          {
            data?.location_txt &&
            data?.location_txt
          }
          <div className="expand-arrow" onClick={() => setExpanded(false)}><MdExpandLess size='1.5rem' style={{opacity: 0.5}}/></div>
      </div> 
    )
    if (!expanded) return (
      <div className="label">
          {
            data?.location_txt &&
            data?.location_txt
          }
        <div  className="expand-arrow" onClick={onAssessmentExpand}><MdExpandMore size='1.5rem' style={{opacity: 0.5}}/></div>
      </div>
    )
  }
  const getClassName = () => {
    let className = 'green';
    if (data?.dt_marked || assessment?.dt_marked || isMessaged) {
      className = 'grey';
    }
    if (expanded) {
      className += ' expanded'
    }
    return className;
  }

  const renderMarkButton = () => {
    const firstName = data.name?.split(' ')[0];
    if (assessment?.dt_marked) return (
      <div className="mark-label">{firstName} was messaged on {assessment?.dt_marked}</div>
    );
    if (isMessaged) return <div className="mark-label">Messaged today</div>;
    return <WhiteButton onClick={onMessageClick} className="message">I messaged {firstName}</WhiteButton>
  }

  const renderDetails = () => {
    if (isLoading) return (
      <div className="loading-assessment">
        <Spinner size="2rem"/>
      </div>
    )

    const firstName = data.name?.split(' ')[0];

    const yoeKeys = Object.keys(assessment?.yoe || {}).filter(k => !!assessment?.yoe[k]);

    return <div className="details">
      <div className="filter-wrapper">
        <div className="why-john" onClick={onWhyJohnClick} >Why {firstName}?</div>
          <div className="employments">
            {
              yoeKeys.map((k, i) => <div key={i} className='employment-item'>
                {k + ': ' + assessment?.yoe[k]}
              </div>)
            }
            {
              assessment?.possibly_immigrant && 
              <div className="employment-item">Possibly Immigrant</div>
            }
          </div>
        <div className="employments" style={{marginTop: '1.5rem'}}>
          <h3>Employment</h3>
          {
            assessment?.employments?.map((item, i) => <div key={i} className='employment-item extra-margin'>
              <div className="employment-item-title-container">
                <label className='employment-item-title'>{item.title}</label>
                {
                  item.was_promoted &&
                  <Pill className="green">promoted</Pill>
                }
                {
                  item.current &&
                  <Pill className="orange">current</Pill>
                }
              </div>
              <div className="employment-item-company-container">
                <label className='employment-item-company'>{item.company_name}</label>
                {
                  item.company_type &&
                  <Pill className="grey">{item.company_type}</Pill>
                }
              </div>
            </div>)
          }
        </div>
        <div className="education">
          <h3>Education</h3>
          {
            assessment?.education?.map((item, i) => <div key={i} className='employment-item extra-margin'>
              <label className='employment-item-title'>{item.institution_name},</label> 
              {
                item?.graduation_txt &&
                <label className='employment-item-company'>{item.graduation_txt},</label> 
              }
              <label className='employment-item-company'>{item.country_abbrev}</label>
            </div>)
          }
        </div>
        {renderMarkButton()}     
      </div>
    </div>
  }
  
  return (
    <Container className={getClassName()}>
      <div className="banner">
        <div className="name">
          {
            data.name &&
            <a href={data.linkedin_url} rel="noreferrer" target="_blank">
              { data.name } 
            </a>
          }
        </div>
        {renderLabel()}
      </div>
      {renderDetails()} 
      {/* <Tooltip id="my-tooltip-rali">
          <div style={{width: '16rem'}}>This is the last modified date we read from the resume file. This date impacts 
            how employment(s) with To date as “present/current/etc” are handled.</div>
      </Tooltip>
      <Tooltip id="matches-role"/>
      <Tooltip id="filter-counter" style={{zIndex: 100}}/> */}
    </Container>
  );
}

export default SourcedProfileListItem;