import { atom } from 'jotai';

export const loadingAtom = atom(false);

export const loginModalAtom = atom(false);

export const contactUsModalAtom = atom(false);

export const parsedResumeModalAtom = atom(false);

export const userAtom = atom(null);

export const signupModalAtom = atom(false);

export const skillAtom = atom(null);

export const rolesAtom = atom(null);

export const titlesAtom = atom(null);

export const statesAtom = atom(null);

export const citiesAtom = atom(null);