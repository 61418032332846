import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import SourcedProfileListItem from "../components/SourcedProfileListItem";
import PaginationControl from "../components/PaginationControl";
import { 
  getSourcedProfiles,
  getSourcedRoles 
} from "../services";
import { useAuth } from '../hooks';
import Spinner from "../components/Spinner";
import BlueButton from "../components/BlueButton";
import PageContainer from "../components/PageContainer";
import { AiFillLinkedin } from 'react-icons/ai';
import { Rating } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css';
import { Tooltip } from 'react-tooltip';
import StyledModal from "../components/StyledModal";
import Select from 'react-select';


const ITEMS_PER_PAGE = 10;


const Container = styled.div`
    margin-top: 7rem;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    box-sizing: border-box;
    align-self: flex-start;
    max-width: 55rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    .content {
      flex: 1;
      position: relative;
      .header {
        font-family: Roboto;
        font-weight: 400;
        font-size: 0.9rem;
        margin-bottom: 2rem;
        margin-top: 1rem;
      }
      .companies-count {
        padding: 1rem;
        margin-top: 0.5rem;
        font-family: Roboto;
        font-weight: 400;
      }
      .company-item {
        padding: 1rem;
        min-height: 3rem;
        margin-top: 0.5rem;
        font-family: Roboto;
        font-weight: 400;
        min-width: 56rem;
        > a {
          color: black;
          text-decoration: none;
          font-size: 1.2rem;
        }
        cursor: pointer;
        width: fit-content;
        > div.description {
          display: flex;
          align-items: flex-start;
          font-size: 0.9rem;
          margin-top: 0.5rem;
          label {
            font-weight: 400;
          }
          .location {
            width: 15rem;
          }
          .lfr {
            width: 30rem;
          }
          .linkedin {
            color: black;
          }
          .rating-container {
            max-width: 6rem;
            display: inline-block;
            margin: 0 1rem;
          }
          .rating-placeholder {
            width: 6rem;
            display: inline-block;
            margin: 0 1rem;
          }
        }  
        &:hover {
          background-color: var(--color-grey-00);
          > a {
            text-decoration: underline;
          }
        }
      }
    }
    .no-roles {
      font-size: 0.9rem;
      margin-top: -0.2rem;
      max-width: 39rem;
      font-family: Roboto;
      font-weight: 400;
      text-align: left;
    }
    .filter-container {
      width: 14rem;
      margin-bottom: 2rem;
    }
    .spacer {
      flex: 1;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      width: 55rem;
    }
    .grey-line {
      width: 56rem;
      height: 0;
      border-bottom: 1px solid var(--light-greye4);
      margin: 2rem 0 1rem;
    }
    .spinner {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      left: 15rem;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .role-select {
    width: 19rem;
    margin-bottom: 1rem;
    .role__single-value, .role__placeholder, .role__input {
      font-weight: 400 !important;
      padding: 0.5rem 0 0.5rem 0.5rem !important;
      font-size: 0.9rem;
      font-family: Roboto;
      color: black;
    }
    .role__control {
      /* background-color: #c8eecf; */
      border-color: hsl(0, 0%, 80%) !important;
      /* border: none !important; */
      box-shadow: none;
      .role__input-container {
        margin: 0;
        padding: 0;
      }
      .role__indicators {
        .role__indicator-separator {
          display: none;
        }
        .role__dropdown-indicator {
          color: black !important;
          font-weight: 500 !important;
          opacity: 0.5;
        }
      }
    }
    .role__menu {
        z-index: 1;
        margin-top: 0 !important;
        padding: 0 !important;
        .role__menu-list {
          z-index: 12;
          padding: 0 !important;
          .role__option {
            color: black;
            height: auto;
            font-weight: 400 !important;
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem !important;
            font-size: 0.9rem;
            box-sizing: border-box;
            font-family: Roboto;
            &.role__option--is-selected {
              color: white;
            }
            &.role__option--is-focused {
              
            }
          }
        }
      }
  }
`

const Sourcing = () => {
  const [ roles, setRoles ] = useState([]);
  const [ selectedRole , setSelectedRole ] = useState(null);
  const [ profiles, setProfiles ] = useState([]);
  const [ page, setPage ] = useState(1);
  const [ loading, setLoading ] = useState(true);
  const [ loadingProfiles, setLoadingProfiles ] = useState(false);
  
  const [ reason, setReason ] = useState(null);
  const profileStore =  useRef({});
  const isLoading = useRef(false);
  useAuth();

  const onPageSelect = (n) => {
    window.scrollTo(0,0);
    setPage(n);
  }

  useEffect(() => {
    if (!isLoading.current) {
      fetchData();
    }
    return () => {
      setSelectedRole(null);
      setRoles([]);
      setProfiles([]);
    }
  }, [])

  const fetchData = async () => {
    setLoading(true);
    isLoading.current = true;
    try {
      const dataRoles = await getSourcedRoles();
      if (dataRoles?.roles) {
        const roles = dataRoles?.roles.map(item => ({...item, label: item.title, value: item.role_id }));
        setRoles(roles);
        if (roles.length > 0) {
          const role = roles[0];
          setSelectedRole(role);
          setLoadingProfiles(true);
          const dataProfiles = await getSourcedProfiles(role.role_id);
          if (dataProfiles?.profiles) {
            setProfiles(dataProfiles?.profiles);
          }
        } 
      }
    } catch(err) {
      setSelectedRole(null);
      setRoles([]);
      setProfiles([]);
      // TODO this should notify the user that a problem occurred
    } finally {
      setLoading(false);
      setLoadingProfiles(false);
      isLoading.current = false;
    }
  }

  const onWhyJohn = (text) => {
    let txt = text.replaceAll("\n", "<br/>");
    setReason(txt);
  }

  const onConclusionClose = () => {
    setReason(null);
  }

  const onRoleSelect = async (role) => {
    if (selectedRole.role_id !== role.role_id) {
      setSelectedRole(role);
      setLoadingProfiles(true);
      onPageSelect(1);
      setProfiles([]);
      try {
        const dataProfiles = await getSourcedProfiles(role.role_id);
        if (dataProfiles?.profiles) {
          setProfiles(dataProfiles?.profiles);
        }
      } catch (err) {

      } finally {
        setLoadingProfiles(false);
      }
    }
  }

  const renderContent = () => {
    if (loading) return (
      <div className="content">
        <div className="spinner">
          <Spinner/>
        </div>
      </div>
    );
    if (loadingProfiles) return (
      <div className="content">
        <Select
          isDisabled={true}
          className="role-select"
          classNamePrefix="role" 
          placeholder="Select role to screen" 
          options={roles}
          onChange={onRoleSelect}
          value={selectedRole}
        />
        <div className="spinner">
          <Spinner/>
        </div>
      </div>
    );

    return  <div className="content">
        <Select
          className="role-select"
          classNamePrefix="role" 
          placeholder="Select role to screen" 
          options={roles}
          onChange={onRoleSelect}
          value={selectedRole}
        />
      {/* {
        profiles.length > 0 &&
        <div className="header">Below are candidates sourced by our AI agent, 
        based on career progression, quality of work experience, internships and school rank.</div>
      }  */}
      {
        profiles.slice((page-1)*ITEMS_PER_PAGE, page*ITEMS_PER_PAGE)
        .map((item, i) => (
          <SourcedProfileListItem 
            key={i+item.profile_id} 
            roleId={selectedRole?.role_id}
            data={item}
            profileStore={profileStore.current}
            onWhyJohn={onWhyJohn}
          />
        ))
      }
      <div className="spacer"/>
      {
        !loading.current &&
        <div className="controls">
          <div/>
          {
            profiles.length < ITEMS_PER_PAGE ?
            <div/> :
            <PaginationControl 
              selected={page} 
              total={Math.ceil(profiles.length/ITEMS_PER_PAGE)} 
              onClick={onPageSelect}/>
          }
        </div>
      }
    </div>
  }

  return (
    <>
    <PageContainer title={'Sourcing Profiles'} selected={'sourcing'}>
      <Container>
      { renderContent() }
      </Container>
    </PageContainer>
    <Tooltip id="my-tooltip"/>
    <StyledModal 
        isOpen={!!reason}
        onClose={onConclusionClose} 
        modalStyle={{
          width: '40rem',
          minWidth: '40rem',
          padding:'2rem 2rem 1rem',
          top: '40%',
          transform: 'translate(-50%, -50%)'
        }}>
        <div style={{
            fontFamily: 'Roboto',
            fontWeight: 400,
            lineHeight: 1.5,
            fontSize: '1rem'
          }} dangerouslySetInnerHTML={{__html: reason}}/>
      </StyledModal>
    </>
  );
};

export default Sourcing;