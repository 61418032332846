import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { 
  getActiveRoles, 
  uploadRoleResumes, 
  getRoleResumeStatus, 
  filterAssessments,
  submitMatch,
  getMatchedAssessments,
  createRole, saveRole 
} from "../services";
import { useAuth } from '../hooks';
import Spinner from "../components/Spinner";
import BlackButton from "../components/BlackButton";
import WhiteButton from "../components/WhiteButton";
import FileSelector from "../components/FileSelector";
import Select from 'react-select';
import ResumeAssesmentListItem from "../components/ResumeAssesmentListItem";
import { askChatGPT } from "../services";
import StyledModal from "../components/StyledModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PaginationControl from "../components/PaginationControl";
import PageContainer from "../components/PageContainer";
import { LuFilter } from "react-icons/lu";
import StyledCheckbox from "../components/StyledCheckbox";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { MdOutlineFileUpload } from "react-icons/md";
import RoleModal from "../components/RoleModal";
import CreateRoleModal from "../components/CreateRoleModal";
import { Tooltip } from 'react-tooltip';


const RESUME_ASSESSMENTS_PER_PAGE = 50;

const Container = styled.div`
    margin-top: 7rem;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    align-self: flex-start;
    max-width: 60rem;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: row;
    .create-role,
    .upload-resume {
      width: auto;
      height: 2rem;
      opacity: 0.7;
      padding: 0 0.5rem;
      font-weight: 500;
      svg {
        display: inline-block;
        margin-right: 0.3rem;
      }
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.7;
      }
      &:focus {
        outline: none;
      }
    }
    .create-role {
      margin-left: 1rem;
    }
    .upload-resume {
    }
    .edit-filters {
      height: 1.8rem;
      width: 2rem;
      opacity: 0.6;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.6;
      }
      &:focus {
        outline: none;
      }
    }
    .empty-filtered-result {
      height: 15rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
    }
    .content {
      flex: 1;
      position: relative;
      padding: 0 0.5rem;
      .table-container {
        position: relative;
        width: 100%;
        margin-top: 1rem;
        .sort-filter-container {
          width: auto;
          position: absolute;
          right: 15.3rem;
          top: 0.4rem;
          &.disabled {
            pointer-events: none;
            opacity: 0.4;
          }
        }
      }
      .react-tabs {
        max-width: 60rem;
        .react-tabs__tab-list {
          margin: 1rem 0 -1.5rem;
          border-bottom: 1px solid hsl(0, 0%, 80%);
          font-size: 0.9rem;
          .react-tabs__tab {
            min-height: 3rem;
            box-sizing: border-box;
            line-height: 3rem;
            padding: 0 1rem;
            font-weight: 400;
            font-family: Roboto;
          }
          .react-tabs__tab--selected {
            border-color: hsl(0, 0%, 80%);
          }
        }
      }
      .content-header {
        display: flex;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.5rem;
        align-items: center;
      }
    }
    .no-roles {
      font-size: 0.75rem;
      line-height: 1.5;
      margin-top: 0.5rem;
      font-weight: 400;
      color: var(--color-gray-1);
    }
    .no-roles2 {
      font-size: 0.9rem;
      margin-top: 1rem;
      font-family: Roboto;
      font-weight: 400;
    }
    .filter-container {
      width: 2rem;
      height: 2.5rem;
      position: absolute;
      right: 15rem;
      translate: 0 -100%;
      top: 2.5rem;
      overflow-x: visible;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        opacity: 0.6;
        &.active {
          color: green;
        }
        &:hover {
          opacity: 0.8;
        }
      }

      .filter-panel {
        position: absolute;
        top: 0;
        left: 50%;
        translate: -50% -100%;
        width: 8.8rem;
        padding: 0.5rem;
        background: white;
        border-radius: 3px;
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        span.checkbox-label {
          font-weight: 400 !important;
        }
        .filter-controls {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          margin-top: 0.5rem;
          button {
            padding: 0.2rem 0.5rem;
            font-size: 0.8rem;
          }
        }
      }

    }
    .spacer {
      flex: 1;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
    }
    .spinner {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      left: 15rem;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  .role-select {
    width: 19rem;
    .role__single-value, .role__placeholder, .role__input {
      font-weight: 400 !important;
      padding: 0.5rem 0 0.5rem 0.5rem !important;
      font-size: 0.9rem;
      font-family: Roboto;
      color: black;
    }
    .role__control {
      /* background-color: #c8eecf; */
      border-color: hsl(0, 0%, 80%) !important;
      /* border: none !important; */
      box-shadow: none;
      .role__input-container {
        margin: 0;
        padding: 0;
      }
      .role__indicators {
        .role__indicator-separator {
          display: none;
        }
        .role__dropdown-indicator {
          color: black !important;
          font-weight: 500 !important;
          opacity: 0.5;
        }
      }
    }
    .role__menu {
        z-index: 1;
        margin-top: 0 !important;
        padding: 0 !important;
        .role__menu-list {
          z-index: 12;
          padding: 0 !important;
          .role__option {
            color: black;
            height: auto;
            font-weight: 400 !important;
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem !important;
            font-size: 0.9rem;
            box-sizing: border-box;
            font-family: Roboto;
            &.role__option--is-selected {
              color: white;
            }
            &.role__option--is-focused {
              
            }
          }
        }
      }
  }
  .sort-select-container{
    width: 14.5rem;
    position: absolute;
    right: 0;
    translate: 0 -100%;
    top: 2.5rem;
    overflow-x: visible;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 1;
    .batch-status {
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      font-family: Roboto;
      font-weight: 400;
      line-height: 1.5;
    }

  }
  .sort-select {
    width: 100%;
    .sort__single-value, .sort__placeholder, .sort__input {
      font-weight: 400 !important;
      padding: 0.5rem 0 0.5rem 0.5rem !important;
      font-size: 0.9rem;
      font-family: Roboto;
    }
    .sort__control {
      /* background-color: #c8eecf; */
      border-color: hsl(0, 0%, 80%) !important;
      /* border: none !important; */
      box-shadow: none;
      .sort__input-container {
        margin: 0;
        padding: 0;
      }
      .sort__indicators {
        .sort__indicator-separator {
          display: none;
        }
        .sort__dropdown-indicator {
          color: black !important;
          font-weight: 400 !important;
          opacity: 0.5;
          padding: 0 0.5rem 0 0;
          margin: 0;
          /* width: 1.5rem; */
        }
      }
    }
    .sort__menu {
        z-index: 1;
        margin-top: 0 !important;
        padding: 0 !important;
        .sort__menu-list {
          z-index: 12;
          padding: 0 !important;
          .sort__option {
            color: black;
            height: auto;
            font-weight: 400 !important;
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem !important;
            font-size: 0.9rem;
            box-sizing: border-box;
            font-family: Roboto;
            &.sort__option--is-selected {
              color: white;
            }
            &.sort__option--is-focused {
              
            }
          }
        }
      }
  }
  .scrollable-table {
    margin-top: 2rem;
    max-width: 60rem;
    overflow: auto;
    max-height: auto;
  }
  .resumes-container {
    width: 50rem;
    height: calc(100vh - 22rem);
    position: relative;
    .spinner {
      top: min(10rem, 30%);
    }
  }
`

const SORT_OPTIONS = [
  {label: 'Date Of Upload', value: 'date_of_upload'},
  {label: 'Most To Least Qualified', value: 'most_to_least_qualified'}
]

const POLLING_TIME = 3000;

const ResumeAssessment = () => {
  const [ roles, setRoles ] = useState([]);
  const [ resumes, setResumes ] = useState([]);
  const [ matchedResumes, setMatchedResumes ] = useState([]);
  const [ selectedRole , setSelectedRole ] = useState(null);
  const [ selectedSortOption, setSelectedSortOption ] = useState(SORT_OPTIONS[0]);
  const [ isFileSelectorOpen, setFileSelectorOpen ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ loadingResumes, setLoadingResumes ] = useState(false);
  const [ conclusion, setConclusion ] = useState(null);
  const isLoading = useRef(false);
  const currentRole = useRef(null);
  const currentSortOption = useRef(SORT_OPTIONS[0].value);
  const tm = useRef(null);
  const chatGPTStore = useRef({});
  const processingInProgress = useRef(false);
  const [ selectedPage, setSelectedPage ] = useState(1);
  const [ cntAssessed, setCntAssessed ] = useState(0);
  const [ tabIndex, setTabIndex ] = useState(0);
  const [ batchStatus, setBatchStatus ] = useState(null);
  const [ isRoleModalOpen, setRoleModalOpen ] = useState(false);
  const [ isCreateModalOpen, setCreateModalOpen ] = useState(false);
  const [ roleData, setRoleData ] = useState();
  const assessmentStore = useRef({});

  const tooltipRef1 = useRef(null);


  useAuth();

  useEffect(() => {
    if (!isLoading.current) {
      fetchRoles();
    }
    return () => {
      setRoles([]);
      setResumes([]);
      setMatchedResumes([]);
      currentRole.current = null;
      currentSortOption.current = SORT_OPTIONS[0].value;
      clearTimeout(tm.current);
    }
  }, [])

  useEffect(() => {
    if (roles.length > 0 && !selectedRole) {
      const url = new URL(window.location.href);
      const role_id = url.searchParams.get("role_id");
      if (role_id) {
        const role = roles.find(item => item.value === Number(role_id));
        if (role) {
          onRoleSelect(role);
        }
      }
    }
  }, [roles, selectedRole])

  const getProcessingResumesCnt = (resumes) => {
    return (resumes || []).filter(item => item.status === 'Processing').length; 
  }

  const trackResumeStatus = async () => {
    clearTimeout(tm.current);
    const role = currentRole?.current;
    try {
      const response = await getRoleResumeStatus(role.value, currentSortOption.current);
      const response2 = await getMatchedAssessments(role.value);
      if (role === currentRole?.current) {
        createAssessmentList({...response, matched_assessments: response2?.resume_assessments || []});
      }  
    } catch(err) {
      processingInProgress.current = true;
    } finally {
      if (processingInProgress.current) {
        tm.current = setTimeout(() => trackResumeStatus(), POLLING_TIME);
      }
    }
  }

  const fetchRoles = async () => {
    setLoading(true);
    isLoading.current = true;
    try {
      const data = await getActiveRoles();
      if (data) {
        const roles = data.map(item => ({...item, label: item.title, value: item.id }));
        setRoles(roles);
      }
    } catch(err) {
      setRoles([]);
      // TODO this should notify the user that a problem occurred
    } finally {
      setLoading(false);
      isLoading.current = false;
    }
  }

  const cleanAssessments = () => {
    assessmentStore.current = {};
  }

  const onRoleSelect = async (role) => {
    clearTimeout(tm.current);
    currentRole.current = role;
    setSelectedRole(role);
    cleanAssessments();
    setResumes([]);
    setMatchedResumes([]);
    setLoadingResumes(true);
    await trackResumeStatus();
    setLoadingResumes(false);
  }

  const onSortOptionSelect = async (option) => {
    currentSortOption.current = option.value;
    setSelectedSortOption(option);
    onRoleSelect(currentRole?.current);
  }

  const onFileSelectorClose = () => {
    setFileSelectorOpen(false);
  }

  const openFileSelectorModal = (item) => {
    setFileSelectorOpen(true);
    document.querySelector('.upload-resume').blur();
  }

  const createAssessmentList = ({
    resume_assessments, 
    cnt_assessed, 
    active_batch_status,
    matched_assessments
  }) => {
    resume_assessments.forEach(item => {
      if (chatGPTStore.current[item.resume_id]) {
        item.chatGPT = chatGPTStore.current[item.resume_id];
      }
    });
    if (
      active_batch_status && 
      active_batch_status.process_completed !== undefined
    ) {
      setBatchStatus(active_batch_status);
    } else {
      setBatchStatus(null);
    }
    setResumes([...resume_assessments]);
    setMatchedResumes([...matched_assessments]);
    setCntAssessed(cnt_assessed);
    if (getProcessingResumesCnt(resume_assessments) > 0) {
      processingInProgress.current = true;
    } else {
      processingInProgress.current = false;
    }
  }

  const onSubmit = async (files, callback) => {
    try {
      processingInProgress.current = true;
      const role = selectedRole;
      const response = await uploadRoleResumes(selectedRole.value, files, (value) => {
        callback(value);
        if (value >= 100) {
          onFileSelectorClose();
          const placeholders = files?.map(item => ({
            resume_file_name: item.name,
            status: "Processing",
            pct_complete: 0,
            resume_id: -1,
          }));
          setResumes([...placeholders, ...resumes]);
        }
      });
      if (role === currentRole?.current) {
        createAssessmentList(response);
      }  
    } catch(err) {
      processingInProgress.current = true;
    } finally {
      if (processingInProgress.current) {
        trackResumeStatus();
      }
    }
  }
 
  const onAskGPTClick = async (resume) => {
    resume.chatGPT = true;
    chatGPTStore.current[resume.resume_id] = true;
    setResumes([...resumes]);
    const res = await askChatGPT(selectedRole.value, resume.resume_id);
    resume.chatGPT = res;
    chatGPTStore.current[resume.resume_id] = res;
    setResumes([...resumes]);
  }

  const onViewGPTClick = async (resume) => {
    let result = "<p>" + resume.chatGPT + "</p>";
    result = result.replace(/\r\n\r\n/g, "</p><p>").replace(/\n\n/g, "</p><p>");
    result = result.replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
    setConclusion(result);
  }

  const onConclusionClose = () => {
    setConclusion(null);
  }

  const onPaginationClick = (n) => {
    if (n !== selectedPage) {
      setSelectedPage(n);
      window.scrollTo(0,0);
    }
  }

  const onTabSelect = (index) => {
    if (index !== tabIndex) {
      setTabIndex(index)
    }
  }

  const onSubmitMatch = async (resume_id) => {
    if (selectedRole?.value && resume_id) {
      try {
        setLoadingResumes(true);
        await submitMatch(selectedRole?.value, resume_id);
      } catch (err) {
        console.log(err);
      } finally {
        onRoleSelect(currentRole?.current);
      }
    }
  }

  const openRoleModal = (item) => {
    setRoleModalOpen(true);
    setRoleData(selectedRole);
    document.querySelector('.edit-filters').blur();
  }

  const onRoleSubmit = async (name) => {
    setRoleModalOpen(false);
    setCreateModalOpen(false);
    setLoadingResumes(true);
    let newRole = await createRole(name);
    newRole={...newRole, label: newRole.title, value: newRole.id}; 
    setRoles([ newRole, ...roles ]);
    onRoleSelect(newRole);
    setLoadingResumes(false);
  }

  const onRoleSave = async (data) => {
    try {
      let role = { id: roleData.id, ...data };
      setRoleModalOpen(false);
      setLoadingResumes(true);
      role = await saveRole(role);
      role = {...role, label: role.title, value: role.id}
      const newRoles = [ role, ...roles.filter(a => a.id !== role.id) ];
      setRoles(newRoles);
      setRoleData(null);
      cleanAssessments();
      setTimeout(() => { 
        onRoleSelect(role);
      }, 50)
    } catch (err) {
      console.log(err);
      cleanAssessments();
      setLoadingResumes(false);
    } 
  }

  const onRoleModalClose = () => {
    setRoleModalOpen(false);
    setRoleData(null);
  }

  const onCreateModalClose = () => {
    setCreateModalOpen(false);
  }

  const openCreateModal = () => {
    setCreateModalOpen(true);
    document.querySelector('.create-role').blur();
  }


  const renderTable = () => {
    if (!selectedRole) return null;
    if (loadingResumes) {
      return <div className="resumes-container">
        <div className="spinner">
          <Spinner/>
        </div>
      </div>
    }
    
    let resumes1 = null;
    let pageCount = 1;
    if (resumes.length > 0) {
      pageCount = Math.ceil(resumes.length / RESUME_ASSESSMENTS_PER_PAGE);
      const selectedPageNumber = Math.min(selectedPage, pageCount);
      if (selectedPageNumber !== selectedPage) {
        setSelectedPage(selectedPageNumber);
      }
      resumes1 = <div className="scrollable-table">
        {
          resumes.slice((selectedPageNumber-1)*RESUME_ASSESSMENTS_PER_PAGE, 
          selectedPageNumber*RESUME_ASSESSMENTS_PER_PAGE).map(
            (item, i) => <ResumeAssesmentListItem 
            key={i + '-' + item.resume_id} 
            data={item} 
            roleId={selectedRole.value}
            submitMatch={onSubmitMatch}
            assessmentStore={assessmentStore.current}
            askChatGPT={() => onAskGPTClick(item)}
            viewChatGPT={() => onViewGPTClick(item)}/>
          )
        }
      </div>
    }
    
    const resumes2 = matchedResumes.length === 0 ? null : (
      <div className="scrollable-table">
        {
          matchedResumes.map((item, i) => <ResumeAssesmentListItem 
            key={i + '-' + item.resume_id} 
            roleId={selectedRole.value}
            assessmentStore={assessmentStore.current}
            data={item} />)
        }
      </div>
    )

    const isDisabled = loadingResumes || (resumes.length === 0 && matchedResumes.length === 0);

    return (
      <div className="table-container">
         <div className={`sort-filter-container${isDisabled ? ' disabled' : ''}`} >
            {
              selectedRole !== null &&
              <LuFilter 
                className="edit-filters"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Add or change role requirements"
                data-tooltip-place="top"
                onClick={openRoleModal}
              />
            }
        </div>

        <div className="sort-select-container">
          {
            batchStatus &&
            <div className='batch-status'>
              <div>Processing Complete: {batchStatus?.process_completed}</div>
            </div>
          }        
          {
            !loadingResumes &&
            <Select
              isDisabled={loadingResumes || (resumes.length === 0 && matchedResumes.length === 0)}
              className="sort-select"
              classNamePrefix="sort" 
              placeholder="Select sort option" 
              isSearchable={false}
              options={SORT_OPTIONS}
              onChange={onSortOptionSelect}
              defaultValue={selectedSortOption}
            />
          }
        </div>
        <Tabs onSelect={onTabSelect}>
        <TabList>
          {
            (resumes.length !== 0 || matchedResumes.length === 0) &&
            <Tab disabled={resumes.length === 0}>Resumes Assessed ({cntAssessed})</Tab>
          }
          {
            matchedResumes.length !== 0 &&
            <Tab disabled={matchedResumes.length === 0}>Matches ({matchedResumes.length})</Tab>
          }
          
          
        </TabList>
        {
          (resumes.length !== 0 || matchedResumes.length === 0) &&
          <TabPanel>
            { resumes1 }
          </TabPanel>
        }
        {
          matchedResumes.length !== 0 &&
          <TabPanel>
            { resumes2 }
          </TabPanel>
        }
        </Tabs>
        {
          pageCount > 1 &&
          tabIndex === 0 &&
          <PaginationControl
            selected={selectedPage}
            total={pageCount}
            onClick={onPaginationClick}
          />
        }
      </div>
    )
  }

  const renderContent = () => {
    if (loading) return (
      <div className="content">
        <div className="spinner">
          <Spinner/>
        </div>
      </div>
    );
    const isProcesssing = getProcessingResumesCnt(resumes) > 0;
    const isDisabled = loadingResumes || roles.length === 0;
    return  <div className="content">
      <div className="content-header">
        <div>
        <Select
          isDisabled={isDisabled}
          className="role-select"
          classNamePrefix="role" 
          placeholder="Select role to screen" 
          options={roles}
          onChange={onRoleSelect}
          value={selectedRole}
        />
        </div>
        <WhiteButton
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Create a role"
            data-tooltip-place="top"
            className="create-role"
            onClick={openCreateModal}
        >
          <MdOutlineAddCircleOutline/> Add Role
        </WhiteButton>
        
        {
          selectedRole !== null &&
          <WhiteButton
            className="upload-resume"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Upload resumes for the selected role"
            data-tooltip-place="top"
            disabled={loadingResumes || isProcesssing}
            onClick={openFileSelectorModal}
          >
            <MdOutlineFileUpload/> Upload
          </WhiteButton>
        }
      </div>
      {
          roles.length === 0 &&
          <div className="no-roles2">Please create a role, you can then upload and assess resumes against the role's filters.
          </div>
        }
      { renderTable() }
      
    </div>
  }

  return (
    <>
      <PageContainer title={'Resume Assessment'} selected={'resume-assessment'}>
        <Container>
          { renderContent() }
        </Container>
      </PageContainer>
      <FileSelector 
        isOpen={ isFileSelectorOpen }
        onSubmit={ onSubmit }
        onClose={ onFileSelectorClose }
        // disabled={fileSelectorDisabled}
      />
      <RoleModal 
        isOpen={ isRoleModalOpen }
        data={ selectedRole }
        onSubmit={ onRoleSave }
        onClose={ onRoleModalClose }
      />
      <StyledModal 
        isOpen={!!conclusion}
        onClose={onConclusionClose} 
        modalStyle={{
          width: '50rem',
          minWidth: '50rem',
          padding:'2rem 2rem 1rem',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
        <div dangerouslySetInnerHTML={{__html: conclusion}}/>
      </StyledModal>
      <CreateRoleModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
        onSubmit={onRoleSubmit}
        roles={roles}
      />
      <Tooltip id="my-tooltip" ref={tooltipRef1}/>
    </>
  );
};

export default ResumeAssessment;