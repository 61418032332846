import React, { useState } from "react";
import styled from 'styled-components';
import Switch from "react-switch";
import { LuFilter } from "react-icons/lu";

const Container = styled.div`
    padding: 0 1rem;
    height: 3rem;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    font-family: Roboto;
    font-weight: 400;
    &:hover {
      background-color: var(--color-grey-00);
    }
    .sourcing-role-title {
      width: 20rem;
    }
    .sourcing-role-conditions {
      font-size: 0.8rem;
      color: var(--color-blue1);
      font-weight: 500;
      display: flex;
      align-items: center;
      text-wrap-mode: nowrap;
      svg {
        width: 1rem;
        height: 1rem;
        padding-left: 0.3rem;
      }
    }
    .sourcing-role-controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap:0.5rem;
      width: 20rem;
      label {
        font-size: 0.8rem;
      }
      button {
        padding: 0.5rem;
        line-height: 1;
        font-size: 0.8rem;
      }
    }
`

const SourcedRoleListItem = ({ data, onChange, onFilterOpen }) => {
  const [checked, setChecked] = useState(data.is_active);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
    onChange(data.role_id, nextChecked);
  };
  
  return (
    <Container className={data.is_active ? 'active' : 'inactive'}>
        <div className="sourcing-role-title">{data.title}</div>
        <div className="sourcing-role-conditions" onClick={onFilterOpen}>
          Sourcing Conditions <LuFilter/>
        </div>
        <div className="sourcing-role-controls">
          <label>Sourcing Active</label>   
          <Switch
            onChange={handleChange}
            checked={checked}
            uncheckedIcon={false}
            checkedIcon={false}
            className="react-switch"
            height={20}
            width={40}
          />
        </div>
    </Container>
  );
}

export default SourcedRoleListItem;